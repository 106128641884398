import gsap from "gsap";

let mm = gsap.matchMedia();
mm.add("(min-width: 480px)", () => {

   //var rootParams = document.querySelector(':root');
   var cursorBox = document.querySelector(".cursor-box");
   var cursor = document.querySelector(".cursor-def");
   var cursorBtn = document.querySelector(".cursor-btn");
   var cursorBtnText = document.querySelector(".cursor-btn-text");
   var cursorRead = document.querySelector(".cursor-read");
   var cursorReadText = document.querySelector(".cursor-read-text");
   var cursorView = document.querySelector(".cursor-view");
   var cursorViewText = document.querySelector(".cursor-view-text");

   var cursorWe = document.querySelector(".cursor-we");
   var cursorDrag = document.querySelector(".cursor-drag");
   var cursorDragBox = document.querySelector(".cursor-drag-box");

   var cursorCaseHover = document.querySelector(".cursor-case-hover");
   var cursorCaseHoverImg = document.querySelector(".cursor-case-hover__img");


   //gsap.set(cursor, {
   //   xPercent: -50,
   //   yPercent: -50
   //});

   window.addEventListener("mousemove", moveCursor);

   function moveCursor(e) {
      gsap.to(cursorBox, 0.1, {
         force3D: true,
         x: e.clientX,
         y: e.clientY
      });
   }


   document.onmouseenter = function () {
      //cursor.style.transform = "scale(0.25)";
      gsap.to(cursor, {
         scale: 0.25,
         force3D: true
      });
   }
   document.onmouseleave = function () {
      //cursor.style.transform = "scale(0)";
      gsap.to(cursor, {
         scale: 0,
         force3D: true
      });
   }





   var menuLinkFix = document.querySelector(".menu-btn-link-fix");
   var bgDotsBurger = document.querySelector(".bg-dots-burger");

   var magneticSizeBurger = document.querySelector(".magnetic-size-burger");
   var magneticSizeBurgerSet = "-11rem";
   var magneticSizeBurgerDef = "-5rem";

   menuLinkFix.onmouseenter = function () {
      cursor.style.transform = "scale(0)";
      bgDotsBurger.style.transform = "scale(1)";
      magneticSizeBurger.style.bottom = magneticSizeBurgerSet;
      magneticSizeBurger.style.top = magneticSizeBurgerSet;
      magneticSizeBurger.style.left = magneticSizeBurgerSet;
      magneticSizeBurger.style.right = magneticSizeBurgerSet;

   };
   menuLinkFix.onmouseleave = function () {
      cursor.style.transform = "scale(0.25)";
      bgDotsBurger.style.transform = "scale(0)";
      magneticSizeBurger.style.bottom = magneticSizeBurgerDef;
      magneticSizeBurger.style.top = magneticSizeBurgerDef;
      magneticSizeBurger.style.left = magneticSizeBurgerDef;
      magneticSizeBurger.style.right = magneticSizeBurgerDef;
   };

   var magneticSizeBall = document.querySelector(".magnetic-size-ball");

   if (magneticSizeBall) {
      var magneticSizeBallSet = "-12rem";
      var magneticSizeBallDef = "-2rem";

      var magnetBall = document.querySelector("#magnetic-wrap-ball");

      magnetBall.onmouseenter = function () {
         cursor.style.transform = "scale(0)";
         magneticSizeBall.style.bottom = magneticSizeBallSet;
         magneticSizeBall.style.top = magneticSizeBallSet;
         magneticSizeBall.style.left = magneticSizeBallSet;
         magneticSizeBall.style.right = magneticSizeBallSet;
      };
      magnetBall.onmouseleave = function () {
         cursor.style.transform = "scale(0.25)";
         magneticSizeBall.style.bottom = magneticSizeBallDef;
         magneticSizeBall.style.top = magneticSizeBallDef;
         magneticSizeBall.style.left = magneticSizeBallDef;
         magneticSizeBall.style.right = magneticSizeBallDef;
      };
   }

   var ceseItemBox = document.querySelectorAll('.cursor-hover-btn');

   ceseItemBox.forEach(function (item, i) {
      item.onmouseenter = function () {
         cursorBtn.style.transform = "scale(1)";
         item.addEventListener("mousemove", moveCursorBtn);
      };
      item.onmouseleave = function () {
         cursorBtn.style.transform = "scale(0)";
      };
   });

   function moveCursorBtn(e) {
      cursorBtnText.style.transform = "rotate(" + e.clientX / 1.9 + "deg)";
   }

   var hoverRead = document.querySelectorAll('.hover-read');

   hoverRead.forEach(function (item, i) {
      item.onmouseenter = function () {
         cursorRead.style.transform = "scale(1)";
         item.addEventListener("mousemove", moveCursorReadBtn);
      };
      item.onmouseleave = function () {
         cursorRead.style.transform = "scale(0)";
      };
   });

   function moveCursorReadBtn(e) {
      cursorReadText.style.transform = "rotate(" + e.clientX / 1.9 + "deg)";
   }


   var hoverView = document.querySelectorAll('.hover-view');

   hoverView.forEach(function (item, i) {
      item.onmouseenter = function () {
         cursorView.style.transform = "scale(1)";
         item.addEventListener("mousemove", moveCursorViewBtn);
      };
      item.onmouseleave = function () {
         cursorView.style.transform = "scale(0)";
      };
   });

   function moveCursorViewBtn(e) {
      cursorViewText.style.transform = "rotate(" + e.clientX / 1.9 + "deg)";
   }



   var focusText = document.querySelector('.focus-text');
   if (focusText) {
      focusText.onmouseenter = function () {
         cursorWe.style.transform = "scale(1)";
         cursor.style.transform = "scale(0)";
         //cursorBox.style.mixBlendMode = "exclusion";
         //cursorBox.style = "scale(0)";

      };
      focusText.onmouseleave = function () {
         cursorWe.style.transform = "scale(0)";
         cursor.style.transform = "scale(0.25)";
         //cursorBox.style.mixBlendMode = "";
      };
   }


   var servicesLink = document.querySelectorAll('.services-list-block__links-link');

   servicesLink.forEach(function (item, i) {
      item.onmouseenter = function () {
         cursor.style.transform = "scale(.1)";
      };
      item.onmouseleave = function () {
         cursor.style.transform = "scale(0.25)";
      };
   });





   //var btnBigHover = document.querySelector(".btn-big");

   //btnBigHover.onmouseenter = function () {
   //   cursor.style.transform = "scale(3.5)";
   //   cursorBox.style.color = "#fff";
   //   cursorBox.style.mixBlendMode = "exclusion";
   //};
   //btnBigHover.onmouseleave = function () {
   //   cursor.style.transform = "scale(0.25)";
   //   cursorBox.style.color = "var(--color-1)";
   //   cursorBox.style.mixBlendMode = "";
   //};


   var wMethodItems = document.querySelector('.w-method__items');
   if (wMethodItems) {
      wMethodItems.onmouseenter = function () {
         cursorDrag.style.transform = "scale(1)";
         wMethodItems.addEventListener("mousemove", rotateCursorDrag);
      };
      wMethodItems.onmouseleave = function () {
         cursorDrag.style.transform = "scale(0)";
      };

      function rotateCursorDrag(e) {
         cursorDragBox.style.transform = "rotate(" + e.clientX / 1.9 + "deg)";
      }
   }


   var darkBg = document.querySelectorAll('.dark-bg');

   darkBg.forEach(function (item, i) {
      item.onmouseenter = function () {
         cursorBox.style.color = "#EAEAF3";
      };
      item.onmouseleave = function () {
         cursorBox.style.color = "var(--color-1)";
      };
   });





   var cursorHover = document.querySelectorAll('.cursor-hover');
   cursorHover.forEach(function (item, i) {
      item.onmouseenter = function () {
         cursor.style.transform = "scale(0.1)";
      };
      item.onmouseleave = function () {
         cursor.style.transform = "scale(0.25)";
      };
   });

   var cursorHover = document.querySelectorAll('#tags .item');
   cursorHover.forEach(function (item, i) {
      item.onmouseenter = function () {
         cursor.style.transform = "scale(0.5)";
      };
      item.onmouseleave = function () {
         cursor.style.transform = "scale(0.25)";
      };
   });
   var cursorHover = document.querySelectorAll('.reviews .carusel__items, .brands .brands-item');
   cursorHover.forEach(function (item, i) {
      item.onmouseenter = function () {
         cursor.style.transform = "scale(0.5)";
      };
      item.onmouseleave = function () {
         cursor.style.transform = "scale(0.25)";
      };
   });


   var cursorHover = document.querySelectorAll('.cases-list__item');
   cursorHover.forEach(function (item, i) {
      item.onmouseover = function () {
         cursorCaseHover.style.transform = "scale(1)";
         //cursorCaseHoverImg.style.transform = "scale(1.1)";
         cursorCaseHoverImg.src = item.dataset.hoverimg;
         //item.addEventListener("mousemove", moveCursorCaseHover); 
      };
      item.onmouseout = function () {
         cursorCaseHover.style.transform = "scale(0)";
         //cursorCaseHoverImg.style.transform = "scale(1)";
      };
   });

   //function moveCursorCaseHover(e) {
   //   cursorCaseHover.style.transform = "rotate(" + e.clientX / 20 + "deg)";
   //}
});