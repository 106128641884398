import Scrollbar from 'smooth-scrollbar';
import ScrollbarPlg, {
   ScrollbarPlugin
} from 'smooth-scrollbar';
//import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Создаем планиг для якорных ссылок с плавной прокруткой.
class AnchorPlugin extends Scrollbar.ScrollbarPlugin {
   static pluginName = 'anchor';

   onHashChange = () => {
      this.jumpToHash(window.location.hash);
   };

   onClick = (event) => {
      const {
         target
      } = event;

      if (target.tagName !== 'A') {
         return;
      }

      const hash = target.getAttribute('href');

      if (!hash || hash.charAt(0) !== '#') {
         return;
      }

      this.jumpToHash(hash);
   };

   jumpToHash = (hash) => {
      //console.log('hash:', hash);
      const {
         scrollbar
      } = this;

      if (!hash) {
         return;
      }

      if (hash === '#top') {
         scrollbar.setMomentum(0, -scrollbar.scrollTop);
      } else {
         console.log('scrollTop:', scrollbar.containerEl.scrollTop);

         scrollbar.scrollIntoView(document.querySelector(hash), {
            offsetTop: -scrollbar.containerEl.scrollTop
         });
      }
   };

   onInit() {
      this.jumpToHash(window.location.hash);

      window.addEventListener('hashchange', this.onHashChange);

      this.scrollbar.contentEl.addEventListener('click', this.onClick);
   }

   onDestory() {
      window.removeEventListener('hashchange', this.onHashChange);

      this.scrollbar.contentEl.removeEventListener('click', this.onClick);
   }
}
///

let mm = gsap.matchMedia();
mm.add("(max-width: 480px)", () => {
   const scroller = "";
   return scroller;
});

mm.add("(min-width: 480px)", () => {
   const scroller = document.querySelector('.scroller');

   class ModalPlugin extends ScrollbarPlugin {
      static pluginName = 'modal';

      static defaultOptions = {
         open: false,
      };

      transformDelta(delta) {
         return this.options.open ? {
            x: 0,
            y: 0
         } : delta;
      }
   }
   Scrollbar.use(ModalPlugin, AnchorPlugin, /* OverscrollPlugin */ );

   // Smooth scroll setup
   //Scrollbar.use(OverscrollPlugin);
   const bodyScrollBar = Scrollbar.init(scroller, {
      damping: 0.07,
      //alwaysShowTracks: true,
      delegateTo: document,
      renderByPixels: false,
      //plugins: {
      //   overscroll: {
      //      damping: 0.1,
      //      maxOverscroll: 50,
      //   } | true,
      //},
   });
   //const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

   bodyScrollBar.setPosition(0, 0);
   bodyScrollBar.track.xAxis.element.remove();

   //console.log(bodyScrollBar.offset);
   //console.log(bodyScrollBar.scrollTop);

   ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
         if (arguments.length) {
            bodyScrollBar.scrollTop = value;
         }
         return bodyScrollBar.scrollTop;
      }
   });

   bodyScrollBar.addListener(ScrollTrigger.update);
   ScrollTrigger.defaults({
      scroller: scroller
   });

   //lock scroll menu open

   var menuBurg = document.querySelector(".menu-btn-link");
   let menuBurgerStat = "close";

   menuBurg.onclick = function () {
      if (menuBurgerStat == "close") {
         bodyScrollBar.updatePluginOptions('modal', {
            open: true
         });
         menuBurgerStat = "open";
      } else {
         bodyScrollBar.updatePluginOptions('modal', {
            open: false
         });
         menuBurgerStat = "close";
      }
   };
   return scroller;
});

let scroller = mm.scroller
export default scroller


// This part is only necessary if you're using ScrollTrigger's markers:
//if (document.querySelector('.gsap-marker-scroller-start')) {
//   const markers = gsap.utils.toArray('[class *= "gsap-marker"]');
//   bodyScrollBar.addListener(({
//      offset
//   }) => gsap.set(markers, {
//      marginTop: -offset.y
//   }));
//}
// End section necessary only if you're using ScrollTrigger's markers